import request from '../request.js';
import public_request from '../public_request'
//获取后台角色列表
export function userSettingList({ pageNo, pageSize }) {
    return request({
        url: '/setting/users',
        method: 'get',
        params: public_request({
            pageNo,
            pageSize
        })
    })
}

// 获取用户详情
export function getSettingUserDetail(data){
    return request({
        url: '/setting/user',
        method: 'get',
        params: public_request(data)
    })
}

// 获取全部后台角色列表
export function getRolesSelect(){
    return request({
        url: '/setting/roles-all',
        method: 'get',
        params: public_request()
    })
}

// 用户启用禁用
export function userEnable(data) {
    return request({
        url: '/setting/user-enable',
        method: 'PUT',
        data: public_request(data)
    })
}

// 新增用户
export function addUser(data) {
    return request({
        url: '/setting/user',
        method: 'post',
        data: public_request(data)
    })
}
// 编辑用户
export function editUser(data) {
    return request({
        url: '/setting/user',
        method: 'put',
        data: public_request(data)
    })
}

// 删除用户
export function delUser(data) {
    return request({
        url: '/setting/user',
        method: 'DELETE',
        data: public_request(data)
    })
}