<template>
  <div>
    <el-card class="card-box">
      <div>
        <el-drawer
          ref="drawer"
          :title="form.id ? '编辑用户' : '新增用户'"
          :before-close="handleClose"
          :visible.sync="showAddOrEdit"
          direction="rtl"
          custom-class="demo-drawer"
          size="683px"
        >
          <div class="drawer-content">
            <el-form
              ref="form"
              :model="form"
              :rules="form.id ? editRules : addRules"
              label-width="80px"
            >
              <!-- 用户名 -->
              <el-form-item
                label="用户名"
                prop="username"
              >
                <el-input
                  v-if="!form.id"
                  v-model="form.username"
                  maxlength="20"
                  show-word-limit
                />
                <span v-else>{{ form.username }}</span>
              </el-form-item>
              <!-- 密码  密码-->
              <el-form-item
                label="密码"
                prop="password"
              >
                <el-input
                  v-model="form.password"
                  maxlength="32"
                  show-word-limit
                  show-password
                />
              </el-form-item>
              <!-- 确认密码  确认密码-->
              <el-form-item
                label="确认密码"
                prop="confirmPassword"
              >
                <el-input
                  v-model="form.confirmPassword"
                  show-password
                />
              </el-form-item>
              <!-- 角色 角色 -->
              <el-form-item
                label="角色"
                prop="roleId"
              >
                <el-select
                  v-model="form.roleId"
                  style="width:100%"
                >
                  <el-option
                    v-for="(item, index) in roleList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <!-- 备注 -->
              <el-form-item
                label="备注"
                prop="remarks"
              >
                <el-input
                  v-model="form.remarks"
                  type="textarea"
                  autocomplete="off"
                  :rows="4"
                  maxlength="128"
                  show-word-limit
                />
              </el-form-item>
            </el-form>
            <div class="drawer-footer">
              <el-button
                type="primary"
                @click="submitAddOrEdit"
              >
                {{
                  form.id ? "保 存" : "创 建"
                }}
              </el-button>
              <el-button @click="handleClose">
                取 消
              </el-button>
            </div>
          </div>
        </el-drawer>

        <el-button
          type="primary"
          size="medium"
          @click="addOrEditDrawer"
        >
          添加用户
        </el-button>
        <el-table
          v-loading="loading"
          v-tableHeight="{ bottomOffset: 80 }"
          :data="userTableData"
          style="width: 100%;margin-top:10px"
          border
          height="100px"
          :row-style="{ height: '0' }"
          :cell-style="{ padding: '0' }"
          :header-cell-style="styleObj"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
          />
          <el-table-column
            prop="username"
            label="用户名"
            width="180"
          />
          <el-table-column
            prop="enabled"
            label="是否启用"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.enabled"
                :active-value="1"
                :inactive-value="0"
                @change="openstatus(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="role"
            label="角色"
          />
          <el-table-column
            prop="creator"
            label="创建人"
          />
          <el-table-column
            prop="remarks"
            label="备注"
          />
          <el-table-column
            label="操作"
            width="150"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="addOrEditDrawer(scope.row)"
              >
                编辑
              </el-button>
              <el-popconfirm
                :title="'确认删除 ' + scope.row.username + '吗？'"
                @confirm="handleDelete(scope.row)"
              >
                <el-button
                  slot="reference"
                  style="color:#D9001B;margin-left:10px"
                  type="text"
                >
                  删除
                </el-button>
              </el-popconfirm>
              <!-- <el-button
                type="text"
                style="color:#D9001B"
                @click="handleDelete(scope.row)"
                >删除</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align:right;margin-top:10px">
          <el-pagination
            :current-page="currentPage"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="userTotal"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  userSettingList,
  userEnable,
  getSettingUserDetail,
  getRolesSelect,
  addUser,
  editUser,
  delUser,
} from "@/api/setting/userSetting";
import regular from "@/functions/regular";
export default {
  data() {
    return {
      styleObj: {
        background: "#f5f7fa",
      },
      showAddOrEdit: false,
      tableHeight: 50,
      userTableData: [],
      userTotal: 0,
      currentPage: 0,
      form: {
        username: "",
        password: "",
        confirmPassword: "",
        roleId: "",
        remarks: "",
      },
      addRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 6, max: 20, message: "长度在 6到 20 个字符", trigger: "blur" },
          { validator: regular.username, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 32, message: "长度在 6到 32 个字符", trigger: "blur" },
          { validator: regular.password, trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: regular.password, trigger: "blur" },
        ],
        roleId: [{ required: true, message: "请选择角色", trigger: "change" }],
      },
      editRules: {
        password: [
          // { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 32, message: "长度在 6到 32 个字符", trigger: "blur" },
          { validator: regular.password, trigger: "blur" },
        ],
        confirmPassword: [
          // { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: regular.password, trigger: "blur" },
        ],
        roleId: [{ required: false, message: "请选择角色", trigger: "change" }],
      },
      roleList: [],
      search: {
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
    };
  },
  created() {
    this.getUserSettingList();
  },
  methods: {
    getUserSettingList() {
      userSettingList(this.search).then((res) => {
        if (res.code == 0) {
          this.loading = true;
          this.userTableData = res.data.list;
          this.userTotal = res.data.total;
          setTimeout(() => {
            this.loading = false;
          }, 500);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getRoles() {},
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getUserSettingList();
    },
    handleCurrentChange(val) {
      this.search.pageNo = val;
      this.currentPage = val;
      this.getUserSettingList();
    },
    addOrEditDrawer(row) {
      this.showAddOrEdit = true;
      getRolesSelect().then((res) => {
        if (res.code == 0) {
          this.roleList = res.data.list;
        }
      });
      if (row.id) {
        this.$nextTick(() => {
          getSettingUserDetail({ userId: row.id }).then((res) => {
            if (res.code == 0) {
              this.form.remarks = res.data.remarks;
              this.form.roleId = res.data.roleId;
              this.form.username = res.data.username;
              this.form.id = row.id;
            } else {
              this.$message.error(res.message);
            }
          });
        });
      }
      this.getRoles();
    },
    // 启用禁用
    openstatus(row) {
      console.log(row);
      let data = {
        userId: row.id,
        enable: row.enabled,
      };
      userEnable(data).then((res) => {
        if (res.code == 0) {
          this.$message.success(row.enabled == 0 ? "禁用成功" : "启用成功");
        } else {
          this.$message.error(res.message);
        }
        this.getUserSettingList();
      });
    },
    // 关闭抽屉弹框
    handleClose() {
      /*
      username: "",
        password: "",
        confirmPassword: "",
        roleId: "",
        remarks: "",
      */
      
      this.$nextTick(() => {
        this.showAddOrEdit = false;
      this.form.id = "";
      this.form.username = "";
      this.form.roleId = "";
      (this.form.password = ""), (this.form.remarks = "");
      this.form.confirmPassword = "";
        this.$refs.form.resetFields();
      });
    },
    // 提交按钮事件
    submitAddOrEdit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // alert("submit!");
          if (this.form.password != this.form.confirmPassword) {
            this.$message.error("输入的密码不一致，请重新输入");
            return;
          }
          if (this.form.id) {
            // 编辑
            let data = {
              userId: this.form.id,
              password: this.form.password ? this.form.password : null,
              roleId: this.form.roleId,
              remarks: this.form.remarks,
            };
            editUser(data).then((res) => {
              if (res.code == 0) {
                this.$message.success("修改用户成功");
                this.handleClose();
                this.getUserSettingList();
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            let data = {
              username: this.form.username,
              password: this.form.password,
              roleId: this.form.roleId,
              remarks: this.form.remarks,
            };
            addUser(data).then((res) => {
              if (res.code == 0) {
                this.$message.success("创建用户成功");
                this.handleClose();
                this.getUserSettingList();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // 做删除操作
    handleDelete(row) {
      delUser({ userId: row.id }).then((res) => {
        if (res.code == 0) {
          this.$message.success("用户删除成功");
          // 这里做删除数据最后一项造成的bug
          if (
            Math.floor((parseInt(this.userTotal) - 1) / this.search.pageSize) ==
              (parseInt(this.userTotal) - 1) / this.search.pageSize &&
            Math.ceil(
              parseInt(this.userTotal) / parseInt(this.search.pageSize)
            ) -
              parseInt(this.search.pageNo) <
              1
          ) {
            this.search.pageNo = this.search.pageNo - 1;
          }
          // 重新加载数据
          this.getUserSettingList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.card-box {
  margin: 10px;
  height: calc(100vh - 170px);
}
/deep/ .el-drawer:focus {
  outline: none;
}
/deep/ .el-drawer__header {
  margin: 0;
  padding: 0;
  margin: 20px 20px 20px 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  // padding: 0;
  span {
    outline: none;
    font-weight: 600;
    color: #000;
  }
}
.drawer-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  .el-form {
    flex: 1;
    height: 0;
    overflow: auto;
  }
  .drawer-footer {
    display: flex;
    justify-content: center;
    // .el-button {
    //   // flex: 1;
    // }
  }
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
}
</style>
